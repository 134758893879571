export function getJobs() {
  return [
    {
      companyName:
        "<a href='https://vitamediagroup.com/' target='_blank'>Vita Media Group</a>",
      position: "Head of Technology",
      location: "Copenhagen, Denmark",
      text: "Hiring, building, and managing internal tech teams. Growing a cross-functional (ops/dev) and cross-country team (Copenhagen-North Macedonia) focusing on internal marketing tools. Solution and system architecture, management of all major internal technical projects since 2020. Co-driving the 2022 redesign of <a href='https://ekstrapoint.com' target='_blank'>EkstraPoint.com</a> with the CTO. Architecting and leading the implementation of the second version of our award-winning <a href='https://vitamediagroup.com/omg-affiliates-are-igb-affiliates-rising-star-of-2022/' target='_blank'>OMG Affiliates</a> system. Co-developed the internal SMTP platform and launched a state-of-the-art, event-driven SMTP bounce processing system, which dramatically decreased bounce rates for tens of millions of email messages a month originating from our internal marketing platform. In 2023, I designed and implemented a mail pooling system that stabilized and increased our baseline email revenue by 80.000€",
      duration: "January 2020 - present",
      skills: getTechnologies([0, 1, 2, 5, 6, 8, 12, 13, 21])
        .concat(getExpertise([0, 1, 2, 3, 4, 5]))
        .concat(getPersonalSkills([0, 1, 2, 3, 4, 5, 6, 7])),
    },
    {
      companyName:
        "<a href='https://vitamediagroup.com/' target='_blank'>Vita Media Group</a>",
      location: "Copenhagen, Denmark",
      position: "Lead Software Developer",
      text: 'Responsible for launching several smaller technical projects. Organizing weekly status meetings for Head and C-level stakeholders. Continuing the development of <a href="https://ekstrapoint.com" target="_blank">EkstraPoint.com</a> and co-driving the switch to an SPA (Angular) based UI. Development and the subsequent launch of <a href="https://financeiq.io/"  target="_blank">financeiq.io</a> "white-labels" (loan forms engine).',
      duration: "May 2018 - January 2020",
      skills: getTechnologies([0, 1, 3, 5, 6, 7, 8, 10, 11, 13, 15])
        .concat(getExpertise([0, 1, 3, 5, 6, 8]))
        .concat(getPersonalSkills([0, 1, 3])),
    },
    {
      companyName:
        "<a href='https://vitamediagroup.com/' target='_blank'>Vita Media Group</a>",
      location: "Copenhagen, Denmark",
      position: "Software Developer",
      text: "Built and launched the POC version of the first casino loyalty platform in the world <a href='https://ekstrapoint.com' target='_blank'>EkstraPoint.com</a>. Responsible for the entire backend development, external games integration and launch, as well as overseeing the front-end development (WordPress) delivered by both internal and external resources.",
      duration: "September 2017 - May 2018",
      skills: getTechnologies([0, 1, 6, 10, 15]).concat(getExpertise([5])),
    },
    {
      companyName:
        "<a href='https://play.google.com/store/apps/details?id=com.musicalarms.spotify' target='_blank'>Music Alarms</a>",
      location: "Copenhagen, Denmark",
      position: "Founder",
      text: "Launched a successful set of mobile alarm clock applications for various music players. The biggest application (<a href='https://play.google.com/store/apps/details?id=com.musicalarms.spotify' target='_blank'>Mornings for Spotify</a>) was downloaded more than 300k times and had several thousand of daily active users. Responsible for development, marketing, and support.",
      duration: "April 2016 - November 2019",
      skills: getTechnologies([0, 14, 4, 10, 11, 15])
        .concat(getExpertise([4, 9, 10]))
        .concat(getPersonalSkills([2, 3, 8])),
    },
    {
      companyName:
        "<a href='https://web.archive.org/web/20150321203420/http://kiggit.com/' target='_blank'> Kiggit (part-time)</a>",
      location: "Copenhagen, Denmark",
      position: "Android Developer",
      text: "Development of the Android version of Kiggit - a social betting app for football fans. We ran through several iterations of the MVP with redesigning of the core in-app user flows (core play loop, onboarding, conversion optimization). The app was developed using both Java and later Kotlin and was based on Model-View-Presenter architecture. We utilized various technologies for tracking, push notifications (GCM, Firebase), and payment management (Google Play, Adyen).",
      duration: "September 2015 - September 2017",
      skills: getTechnologies([0, 14, 4, 15])
        .concat(getExpertise([10]))
        .concat(getPersonalSkills([0])),
    },
    {
      companyName:
        "<a href='https://www.linkedin.com/company/awis-spr%C3%A1va-syst%C3%A9my-s-r-o-/' target='_blank'>A.W.I.S. Správa, systémy (part-time)</a>",
      location: "Prague, Czech Republic",
      position: "Android Developer",
      text: "Development of a multi-purpose point-of-sale system for Android. Managed to consolidate all previous company's Android POS applications into a single, configurable one. Development of both offline and online versions with the ability to work with the main cash register and sync among multiple Android devices using Google Cloud Messaging. Connectivity to the peripheral receipt and fiscal printers (via both Bluetooth and Ethernet).",
      duration: "August 2014 - July 2015",
      skills: getTechnologies([0, 14, 4, 15, 11])
        .concat(getExpertise([10]))
        .concat(getPersonalSkills([0, 3])),
    },
    {
      companyName:
        "<a href='https://www.linkedin.com/company/atlas-consulting-spol-s-r-o-/' target='_blank'>ATLAS consulting (part-time)</a>",
      location: "Prague, Czech Republic",
      position: "Java Developer",
      text: "Development of new modules for the existing company web application.",
      duration: "March 2014 - August 2014",
      skills: getTechnologies([0, 15, 18, 16]),
      // .concat(getExpertise([10]))
      // .concat(getPersonalSkills([0, 3])),
    },
    {
      companyName:
        "<a href='https://ecu.cz/' target='_blank'>Ecu (contract)</a>",
      location: "Prague, Czech Republic",
      position: "Android Developer",
      text: "Development of an Android app for memlook.com. The project is no longer maintained.",
      duration: "September 2013 - Jun 2015",
      skills: getTechnologies([0, 15, 4])
        .concat(getExpertise([10]))
        .concat(getPersonalSkills([0, 3])),
    },
    {
      companyName:
        "<a href='https://www.dolphinconsulting.cz/en/' target='_blank'>dolphin consulting (part-time/contract)</a>",
      location: "Prague, Czech Republic",
      position: "Java Developer",
      text: "I developed a web application similar to Google Sheets or MS Excel which was designed to simplify the insertion of users' tabular data into an SQL database (MSSQL). Support for both user and administrator view (IAM functionalities).",
      duration: "July 2013 - December 2014",
      skills: getTechnologies([0, 15, 20, 3, 16])
        // .concat(getExpertise([10]))
        .concat(getPersonalSkills([0])),
    },
  ];
}

export function getEducationHistory() {
  return [
    {
      school: "University of Copenhagen",
      department: "Department of Computer Science (DIKU)",
      title: "Master of Science (MSc)",
      text: "Finished with a master thesis called \"<a href='https://soeg.kb.dk/permalink/45KBDK_KGL/1pioq0f/alma99124202959905763' target='_blank'>Hand detection for context–aware mobile applications</a>\" where I built a classifier capable of detecting one of twelve ways of holding and operating a mobile device with 95% accuracy.",
      duration: "2015 - 2018",
    },
    {
      school: "Czech Technical University in Prague",
      department:
        "Faculty of Information Technology (Department of Information Systems and Management)",
      title: "Bachelor degree (Bc)",
      text: 'Finished with bachelor thesis "<a href="https://dspace.cvut.cz/handle/10467/63166" target="_blank">Designing Service for Product Placement Support in Social Media</a>". I recognized the future potential of influencer marketing and therefore analyzed the possibilities of detecting early-stage influencers. Built an initial POC application built using a graph database (Neo4J) in order to identify such individuals.',
      duration: "2012 - 2015",
    },
  ];
}

export function getCertifications() {
  return [
    {
      name: "AWS Certified Solutions Architect - Associate",
      company: "Amazon Web Services (AWS)",
      text: "<a href='https://aw.certmetrics.com/amazon/public/verification.aspx' target='_blank'>Credential ID N7308WTDKF4Q14G8</a>",
      duration: "November 2020",
    },
    {
      name: "Amazon Web Services Cloud Practitioner",
      company: "Amazon Web Services (AWS)",
      text: "<a href='https://aw.certmetrics.com/amazon/public/verification.aspx' target='_blank'>Credential ID 9857KV1L1M1EQY5D</a>",
      duration: "November 2020",
    },
    {
      name: "DADIU game development alumni",
      company: "Danish National Academy of Digital Interactive Enterntainment",
      text: "I gained experience with developing games in Unity. Participated in the development of a game called \"<a href='http://www.dadiu.dk/waddle-away' target='_blank'>Waddle Away</a>\" where I was a member of a twenty people team.",
      duration: "2016",
    },
  ];
}

function getIndicesFromMap(mp, idx = []) {
  if (idx.length > 0) {
    return idx.reduce((acc, i) => {
      acc.push(mp.get(i));
      return acc;
    }, []);
  } else {
    return [...mp.values()];
  }
}

const technologies = new Map();
technologies.set(0, "Java");
technologies.set(1, "AWS");
technologies.set(2, "Docker");
technologies.set(3, "JavaScript");
technologies.set(4, "Android SDK");
technologies.set(5, "Shell Scripting");
technologies.set(6, "PostgreSQL");
technologies.set(7, "MySQL");
technologies.set(8, "Angular");
technologies.set(9, "Tailwind");
technologies.set(10, "WordPress Development");
technologies.set(11, "PHP");
technologies.set(12, "Terraform");
technologies.set(13, "Node.js");
technologies.set(14, "Kotlin");
technologies.set(15, "Git");
technologies.set(16, "Tomcat");
technologies.set(17, "Apache");
technologies.set(18, "Cassandra DB");
technologies.set(19, "Node4j");
technologies.set(20, "MSSQL");
technologies.set(21, "Python");
technologies.set(22, "TypeScript");
technologies.set(23, "Chrome/Safari Extension Development");
technologies.set(24, "Cypress");
technologies.set(25, "Redis");
export function getTechnologies(idx = []) {
  return getIndicesFromMap(technologies, idx);
}

const expertise = new Map();
expertise.set(0, "Software Architecture");
expertise.set(1, "Domain Driven Design");
expertise.set(2, "Clean Architecture");
expertise.set(3, "Cloud Computing");
expertise.set(4, "Cloud Infrastructure Management");
expertise.set(5, "Technical Documentation");
expertise.set(6, "Event Driven Architecture");
expertise.set(7, "Extreme Programming");
expertise.set(8, "Agile / Scrum");
expertise.set(9, "Search Engine Optimization");
expertise.set(10, "App Store Optimization");
expertise.set(11, "Business Intelligence");
expertise.set(12, "Process Automation");
export function getExpertise(idx = []) {
  return getIndicesFromMap(expertise, idx);
}

const personalSkills = new Map();
personalSkills.set(0, "Requirements Analysis");
personalSkills.set(1, "Project Planning");
personalSkills.set(2, "Project Management");
personalSkills.set(3, "Critical Thinking");
personalSkills.set(4, "Team Management");
personalSkills.set(5, "Hiring / Interviews");
personalSkills.set(6, "One On One");
personalSkills.set(7, "Quality Assurance");
personalSkills.set(8, "Entrepreneurship");
personalSkills.set(9, "Detail Oriented");

export function getPersonalSkills(idx = []) {
  return getIndicesFromMap(personalSkills, idx);
}
