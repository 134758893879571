import "./App.css";
import {
  getEducationHistory,
  getJobs,
  getTechnologies,
  getCertifications,
  getExpertise,
  getPersonalSkills,
} from "./values";

import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";

// import jobs from "./values.js";

function App() {
  return (
    <div class="flex flex-col items-center">
      <div class="max-w-7xl relative text-gray-800 bg-gray-50 dark:bg-gray-700 dark:text-gray-300">
        <header class="px-4 md:px-20 print:invisible print:h-20">
          <nav class="w-full">
            <div class="flex justify-between w-full py-8">
              <div>
                <ul class="flex flex-row space-y-0 space-x-6 text-xl md:text-2xl">
                  <li>
                    <ContactModal isOpen={false}></ContactModal>
                  </li>
                  <li class="text-left">
                    <button
                      class="hover:text-blue-300"
                      onClick={() => window.print()}
                      title="Print a fresh copy of this website as a nicely formatted CV"
                    >
                      CV
                    </button>
                  </li>
                </ul>
              </div>
              <div>
                <ul class="flex flex-col xs:flex-row items-end space-x-4 ">
                  <li>
                    <a
                      href="https://github.com/martinvano"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Github"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-9 h-9 bi bi-github"
                        fill="currentColor"
                        rel="noreferrer"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z" />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/martin-vano/"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="linkedin"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-9 h-9 rounded-full bi bi-linkedin"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
        <section class="flex flex-col items-center mb-8 print:my-4 md:w-full print:w-full print:-mt-12">
          <div class="flex flex-col md:flex-row md:justify-center print:flex-row print:justify-center items-center text-center w-full mb-8">
            <img
              class="object-cover w-40 h-40 rounded-full xs:mb-4 md:mb-0"
              src="mv-profile.webp"
              alt="Martin Vano - Software Engineer"
            />

            <div class="flex flex-col w-full md:w-2/4 print:w-2/4">
              <h1 class="text-4xl sm:text-5xl md:text-6xl print:text-6xl lg:text-7xl">
                Martin Vano
              </h1>
            </div>
          </div>
          <div class="flex flex-col w-full space-y-2 text-left mb-8 px-4 md:px-20 print:px-20 ">
            <p>
              As a Head of Technology at Vita Media Group, my focus has been on the hands-on development and architectural leadership of critical software solutions that underpin the company's growth and innovation. With over four years dedicated to advancing our technological capabilities, I played a pivotal role in architecting and coding key projects, such as the award-winning OMG Affiliates system, an internal SMTP platform, and an innovative event-driven bounce processing system. My expertise extends beyond coding, involving the strategic growth of tech talent, fostering a collaborative environment for cross-functional teams, and tactical focus on technical solutions that make a short and long-term impact.
            </p>
            <p>
              I am a certified AWS Solutions Architect with strong skills in various backend and frontend stacks. I have a master's degree in Computer Science from Københavns Universitet and a bachelor's degree in Information Systems and Management from Czech Technical University in Prague. Before joining Vita Media Group, I founded and launched a successful set of Android mobile alarm clock applications for various music players, which were downloaded more than 300k times and had several thousand daily active users.
            </p>
            <p>
              I'm an entrepreneur in my heart and I bring innovation in areas where it's needed. I love digitalizing and optimizing processes. I have a healthy approach to risk-taking. I believe that understanding business comes first, coding second. I value a pragmatic approach when it comes to development. I get things done.
            </p>
          </div>
          <div class="px-4 md:px-8 mb-8 print:mb-4 print:px-8 w-full">
            <hr class="border-gray-400" />
          </div>
          <div class="flex flex-col md:flex-row print:flex-row px-4 md:px-20 print:px-20 w-full gap-2">
            <a
              class="w-full md:w-1/3 print:w-1/3 text-center md:text-left print:text-left"
              href="mailto:martinvano@martinvano.sk"
            >
              martinvano@martinvano.sk
            </a>
            <p class="w-full md:w-1/3 print:w-1/3 text-center">
              <a href="tel:004571388808">(DK) +45 71 38 88 08</a>
            </p>
            <p class="w-full md:w-1/3 print:w-1/3 text-center md:text-right print:text-right">
              <a href="tel:00421950545878">(SK) +421 950 545 878</a>
            </p>
          </div>
        </section>
        <hr class="border-gray-400 mx-8" />
        <section
          class="px-4 md:px-20 print:px-20 my-8 print:my-4"
          id="experience"
        >
          <h2 class="mb-8 print:mb-4 print:mt-8 text-3xl font-bold text-left">
            Professional Experience
          </h2>
          {getJobs().map((job) => (
            <div
              key={job.position + job.duration}
              style={{ a: "hover:text-blue-600" }}
              className="grid grid-cols-1 my-4 print:my-4 print:break-inside-avoid-page"
            >
              <div class="flex flex-col col-span-2 mb-4 md:mb-0 print:mb-0 text-left">
                <h3
                  class="text-lg md:text-xl font-bold"
                  dangerouslySetInnerHTML={{
                    __html: "<b>" + job.position + "</b> at " + job.companyName,
                  }}
                >
                  {/* {job.position} at {job.companyName} */}
                </h3>
                <h4 class="text-sm mb-1">
                  <i>
                    {job.location} ({job.duration})
                  </i>
                </h4>
              </div>
              <div class="flex flex-col col-span-4 text-left">
                <p dangerouslySetInnerHTML={{ __html: job.text }}></p>
              </div>
              {job.skills && (
                <div class="text-sm mt-1">
                  <b class="mr-1">Tools, Technologies, Skills: </b>
                  <i>
                    {job.skills.map((skill) => (
                      <span>
                        {skill +
                          (Object.keys(job.skills).length - 1 ===
                            job.skills.indexOf(skill)
                            ? ""
                            : ", ")}
                      </span>
                    ))}
                  </i>
                </div>
              )}
            </div>
          ))}
        </section>
        <hr class="border-gray-400 mx-8" />
        <section class="px-4 md:px-20 print:px-20 my-8 print:my-4 print:break-inside-avoid-page">
          <h4 class="mb-4 text-3xl font-bold text-left">Education</h4>
          {getEducationHistory().map((education) => (
            <div
              key={education.school + education.duration}
              class="grid grid-cols-1 my-2 text-left print:break-inside-avoid-page"
            >
              <h5 class="text-xl font-bold">{education.title}</h5>
              <h6 class="text-lg font-bold">
                {education.school +
                  (education.department ? " - " + education.department : "")}
              </h6>
              <p> {education.duration}</p>
              <p dangerouslySetInnerHTML={{ __html: education.text }}></p>
            </div>
          ))}
        </section>
        <hr class="border-gray-400 mx-8" />
        <section class="px-4 md:px-20 print:px-20 my-8 print:my-4 print:break-inside-avoid-page">
          <h4 class="mb-4 text-3xl font-bold text-left">Certifications</h4>
          {getCertifications().map((cert) => (
            <div
              key={cert.name}
              class="grid grid-rows my-2 text-left print:break-inside-avoid-page"
            >
              <h5 class="text-xl  font-bold">{cert.name}</h5>
              <h6 class="text-lg font-bold">{cert.company}</h6>
              <p> {cert.duration}</p>
              <p dangerouslySetInnerHTML={{ __html: cert.text }}></p>
            </div>
          ))}
        </section>
        <hr class="border-gray-400 mx-8" />
        {tagsArea("Technologies", getTechnologies())}
        {tagsArea("Expertise", getExpertise())}
        {tagsArea(
          "Interpersonal, management, and other skills",
          getPersonalSkills()
        )}
      </div>
      <footer class="w-full bottom-0 p-8 px-16 print:px-20 bg-gray-800 text-white print:invisible print:h-0">
        <div class="flex flex-col items-center w-full">
          <h3 class="text-xl font-bold mb-2" id="contact">
            Contact
          </h3>
          <div class="flex flex-row print:row-span-8 justify-center items-center flex-wrap w-full gap-2 text-center print:text-left">
            <a href="mailto:martinvano@martinvano.sk">
              martinvano@martinvano.sk
            </a>
            <p>
              <a href="tel:004571388808">(DK) +45 71 38 88 08</a>
            </p>
            <p>
              <a href="tel:00421950545878">(SK) +421 950 545 878</a>
            </p>
          </div>
        </div>
        <p class="print:invisible text-xs text-center mt-6">
          Last updated in January 2024
        </p>
      </footer>
    </div>
  );
}

export default App;

function tagsArea(title, arr) {
  return (
    <section class="px-4 md:px-20 print:px-20 my-8 print:my-4 print:break-inside-avoid-page">
      <h4 class="mb-4 text-3xl font-bold text-left">{title}</h4>
      <div class="flex flex-row flex-wrap w-full gap-2 md:px-0 print:px-0">
        {arr.map((tag) => {
          return (
            <span
              class={`bg-gray-100 text-gray-800 text-xs print:text-base font-semibold px-1 print:px-0 py-1 print:py-0 rounded dark:bg-stone-900 dark:text-gray-300`}
            >
              {tag}
              <span class="hidden print:inline">
                {Object.keys(arr).length - 1 === arr.indexOf(tag) ? "" : ", "}
              </span>
            </span>
          );
        })}
      </div>
    </section>
  );
}

function ContactModal() {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <div>
        <button
          type="text"
          onClick={openModal}
          className="hover:text-blue-300 bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
        >
          Contact
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10 print:h-0"
          onClose={closeModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto ">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 mb-4 text-gray-900"
                  >
                    How would you like to get in touch with me?
                  </Dialog.Title>
                  <div class="grid grid-rows-3 grid-flow-col gap-1 w-full text-left">
                    <a
                      class="text-gray-800 hover:text-blue-600 w-full p-1"
                      id="contact-popup-email"
                      title="Open your preferred email client and send me an email"
                      href="mailto:martinvano@martinvano.sk"
                    >
                      martinvano@martinvano.sk
                    </a>
                    <a
                      class="text-gray-800 hover:text-blue-600 w-full p-1"
                      href="tel:004571388808"
                      title="Call me"
                      id="contact-popup-mobile-dk"
                    >
                      (DK) +45 71 38 88 08
                    </a>
                    <a
                      class="text-gray-800 hover:text-blue-600 w-full p-1"
                      href="tel:00421950545878"
                      title="Call me"
                      id="contact-popup-mobile-sk"
                    >
                      (SK) +421 950 545 878
                    </a>
                    <button
                      className="flex flex-col items-center md:items-end  md:pr-2  justify-center hover:bg-gray-50"
                      title="Copy to clipboard"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          "martinvano@martinvano.sk"
                        );
                        document.querySelector(
                          "#contact-popup-email"
                        ).innerHTML = "Coppied!";
                        setTimeout(closeModal, 1000);
                      }}
                    >
                      {getCopyIcon()}
                    </button>
                    <button
                      className="flex flex-col  items-center md:items-end  md:pr-2  justify-center hover:bg-gray-50"
                      title="Copy to clipboard"
                      onClick={() => {
                        navigator.clipboard.writeText("004571388808");
                        document.querySelector(
                          "#contact-popup-mobile-dk"
                        ).innerHTML = "Coppied!";
                        setTimeout(closeModal, 1000);
                      }}
                    >
                      {getCopyIcon()}
                    </button>
                    <button
                      className="flex flex-col  items-center md:items-end md:pr-2 justify-center hover:bg-gray-50"
                      title="Copy to clipboard"
                      onClick={() => {
                        navigator.clipboard.writeText("00421950545878");
                        document.querySelector(
                          "#contact-popup-mobile-sk"
                        ).innerHTML = "Coppied!";
                        setTimeout(closeModal, 1000);
                      }}
                    >
                      {getCopyIcon()}
                    </button>
                  </div>

                  <div className="flex flex-col items-end mt-4 w-full">
                    <button
                      type="button"
                      className="content-end rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Got it, thanks!
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

function getCopyIcon() {
  return (
    <>
      <svg
        version="1.1"
        viewBox="0 0 55.699 55.699"
        className="max-w-4 max-h-4"
      >
        <g>
          <path
            d="M51.51,18.001c-0.006-0.085-0.022-0.167-0.05-0.248c-0.012-0.034-0.02-0.067-0.035-0.1
		c-0.049-0.106-0.109-0.206-0.194-0.291v-0.001l0,0c0,0-0.001-0.001-0.001-0.002L34.161,0.293c-0.086-0.087-0.188-0.148-0.295-0.197
		c-0.027-0.013-0.057-0.02-0.086-0.03c-0.086-0.029-0.174-0.048-0.265-0.053C33.494,0.011,33.475,0,33.453,0H22.177
		c-3.678,0-6.669,2.992-6.669,6.67v1.674h-4.663c-3.678,0-6.67,2.992-6.67,6.67V49.03c0,3.678,2.992,6.669,6.67,6.669h22.677
		c3.677,0,6.669-2.991,6.669-6.669v-1.675h4.664c3.678,0,6.669-2.991,6.669-6.669V18.069C51.524,18.045,51.512,18.025,51.51,18.001z
		 M34.454,3.414l13.655,13.655h-8.985c-2.575,0-4.67-2.095-4.67-4.67V3.414z M38.191,49.029c0,2.574-2.095,4.669-4.669,4.669H10.845
		c-2.575,0-4.67-2.095-4.67-4.669V15.014c0-2.575,2.095-4.67,4.67-4.67h5.663h4.614v10.399c0,3.678,2.991,6.669,6.668,6.669h10.4
		v18.942L38.191,49.029L38.191,49.029z M36.777,25.412h-8.986c-2.574,0-4.668-2.094-4.668-4.669v-8.985L36.777,25.412z
		 M44.855,45.355h-4.664V26.412c0-0.023-0.012-0.044-0.014-0.067c-0.006-0.085-0.021-0.167-0.049-0.249
		c-0.012-0.033-0.021-0.066-0.036-0.1c-0.048-0.105-0.109-0.205-0.194-0.29l0,0l0,0c0-0.001-0.001-0.002-0.001-0.002L22.829,8.637
		c-0.087-0.086-0.188-0.147-0.295-0.196c-0.029-0.013-0.058-0.021-0.088-0.031c-0.086-0.03-0.172-0.048-0.263-0.053
		c-0.021-0.002-0.04-0.013-0.062-0.013h-4.614V6.67c0-2.575,2.095-4.67,4.669-4.67h10.277v10.4c0,3.678,2.992,6.67,6.67,6.67h10.399
		v21.616C49.524,43.26,47.429,45.355,44.855,45.355z"
          />
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    </>
  );
}
